<template>
  <div class="mt-5 mt-xl-4 custom-height mb-5 mb-md-0 position-relative">
    <img src="../../assets/images/contact-map-locations.webp" class="img-map" alt="..." />
    <img
      src="../../assets/images/contact-white-rounded-bg.svg"
      class="img-rounded-white"
      alt="..."
    />
    <div class="card-position">
      <div class="card">
        <div class="card-body">
          <div class="row h-100">
            <div class="col-md-6 mb-3 mb-md-0 d-flex flex-column justify-content-center">
              <div class="heading-04 text-neutral-07 text-center mb-4">Contact</div>
              <Form
                ref="contactUsFormRef"
                @submit="Submit"
                :validation-schema="Schema"
                v-slot="{ errors }"
              >
                <div class="mb-4" :class="{ 'invalid-input': errors.name }">
                  <label for="name" class="field-label">Nume</label>
                  <Field
                    v-model="contact.Name"
                    name="name"
                    class="form-control"
                    id="name"
                    placeholder="Nume..."
                    type="text"
                  />
                  <ErrorMessage name="name" class="error-message" />
                </div>
                <div class="mb-4" :class="{ 'invalid-input': errors.email }">
                  <label for="contact-email" class="field-label">Email</label>
                  <Field
                    v-model="contact.Email"
                    name="email"
                    class="form-control"
                    id="contact-email"
                    placeholder="Email..."
                    type="email"
                  />
                  <ErrorMessage name="email" class="error-message" />
                </div>
                <div class="mb-4" :class="{ 'invalid-input': errors.phoneNumber }">
                  <label for="phoneNumber" class="field-label">Telefon</label>
                  <Field
                    v-model="contact.PhoneNumber"
                    name="phoneNumber"
                    class="form-control"
                    id="phoneNumber"
                    placeholder="Telefon..."
                    type="tel"
                  />
                  <ErrorMessage name="phoneNumber" class="error-message" />
                </div>
                <div class="mb-4" :class="{ 'invalid-input': errors.message }">
                  <label for="message" class="field-label">Mesaj</label>
                  <Field
                    v-model="contact.Message"
                    name="message"
                    class="form-control"
                    id="message"
                    placeholder="Mesaj..."
                    type="text"
                    as="textarea"
                    rows="3"
                  />
                  <ErrorMessage name="message" class="error-message" />
                </div>
                <button type="submit" class="button btn-medium btn-primary-solid d-block m-auto">
                  <span class="d-flex align-items-center justify-content-center">
                    Trimite
                    <img
                      src="../../assets//images/icons/arrow-right-20x20.svg"
                      class="ms-2"
                      alt="..."
                    />
                  </span>
                </button>
              </Form>
            </div>
            <div class="col d-flex flex-column justify-content-center">
              <div class="bg-right-info">
                <div class="text-center">
                  <a
                    title="Vezi locația pe Google Maps"
                    href="https://www.google.com/maps?q=47.63939552968071,26.24357854303996"
                    target="_blank"
                    class="icon"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="../../assets/images/icons/location-bold-20x20.svg"
                      alt="..."
                      width="20"
                      height="20"
                    />
                  </a>
                  <div class="text-button-01 text-primary-05 mb-1">Suceava</div>
                  <div class="text-button-03 text-neutral-07">
                    Bulevardul 1 Mai nr. 6, Suceava 720224
                  </div>
                </div>
                <div class="text-center">
                  <a href="tel:0759205637" target="_blank" class="icon" title="Contactează-ne">
                    <img src="../../assets/images/icons/call-bold-20x20.svg" alt="..." />
                  </a>
                  <div class="text-button-01 text-primary-05">Telefon</div>
                  <div class="text-button-01 text-neutral-07">+40 759 205 637</div>
                </div>
                <div class="text-center">
                  <div class="icon" style="pointer-events: none">
                    <img src="../../assets/images/icons/white-calendar-bold-20x20.svg" alt="..." />
                  </div>
                  <div class="text-button-01 text-primary-05 mb-1">Program</div>
                  <div class="text-button-03 text-neutral-07">
                    <div class="fw-normal mb-2">
                      Luni - Vineri: <span class="fw-semibold">10 am - 8 pm</span>
                    </div>
                    <div class="fw-normal mb-2">
                      Sâmbătă: <span class="fw-semibold">10 am - 8 pm</span>
                    </div>
                    <div class="fw-normal">Duminică: <span class="fw-semibold">Închis</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'ContactView ',
  data() {
    return {
      contact: {
        Name: '',
        Email: '',
        PhoneNumber: '',
        Message: '',
      },
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    Submit() {
      this.$axios
        .post('/Settings/sendMessage', this.contact)
        .then(() => {
          this.$utils.ToastNotify('success', 'Email-ul a fost trimis cu success.');
        })
        .catch(() => {
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
  },
  computed: {
    Schema() {
      return yup.object().shape({
        name: yup
          .string()
          .required('Numele este obligatoriu.')
          .min(3, 'Numele trebuie sa aibă mininum 3 caractere.')
          .trim(),
        email: yup
          .string()
          .required('Email-ul este obligatoriu.')
          .email('Email-ul nu este valid.')
          .trim(),
        phoneNumber: yup
          .string()
          .trim()
          .test('validate-phone', 'Numărul de telefon nu este valid.', (phoneNumber) => {
            if (!phoneNumber) return true;
            if (phoneNumber.length < 10) return false;
            return /^([\\+]?40[-]?|[0])?[1-9][0-9]{8}$/.test(phoneNumber);
          }),
        message: yup
          .string()
          .notRequired()
          .matches(/^(|.{5,500})$/, 'Descrierea trebuie să fie intre 5 și 500 caractere.')
          .trim(),
      });
    },
  },
};
</script>
<style scoped>
.icon:hover {
  background: var(--primary-6);
  box-shadow: 0px 0px 20px 0px rgba(214, 54, 108, 0.3);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--primary-5);
  box-shadow: 0px 0px 20px 0px rgba(214, 54, 108, 0.3);
  width: 36px;
  height: 36px;
  transition: 0.2s;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
}
.btn-medium {
  max-width: 200px;
  width: 100%;
}
.bg-right-info {
  padding: 24px;
  border-radius: 16px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  max-height: 589px;
}
.card-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card {
  box-shadow: 0px 5px 60px 0px #0000001a;
  border-radius: 24px;
  border: 0px;
  max-width: 805px;
  width: 100%;
  min-height: 637px;
}

@media (min-width: 768px) {
  .custom-height {
    height: 85vh;
  }
  .card-position {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
/* @media (min-height: 850px) {
  .contact-page {
    height: calc(100vh - 185px);
    margin-top: 0;
    margin-bottom: 0;
  }
} */

/* .img-top-left-cookies {
  position: absolute;
  left: 0;
  top: 0;
} */

.img-map {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.img-rounded-white {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
